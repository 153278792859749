import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'

const TooltipContainer = styled.div`
  // width: 228px;
  padding: 0.6rem 1rem;
  line-height: 100%;
  font-weight: 400;
  font-family: 'Poppins';
  font-size: 14px;
  a {
    text-decoration: none;
    color: rgb(66, 155, 255);
  }
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: string | any,
  linkText?: string,
  linkUrl?: string
}

export default function Tooltip({ text, linkText, linkUrl, ...rest }: TooltipProps) {
  return <Popover content={<TooltipContainer>{text} {linkUrl && <a href={linkUrl}>{linkText}</a>}</TooltipContainer>} {...rest} />
}

export function MouseoverTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  )
}
