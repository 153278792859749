import { useFetchArenaData } from 'data/arena/overview'
import { useEffect } from 'react'
import { useArenaData } from './hooks'

export default function Updater(): null {
  const [arenaData, updateArenaData] = useArenaData()
  // console.log('updater')

  const { data: fetchedArenaData, error, loading } = useFetchArenaData()
  if (error) {
    console.log(error)
  }
  // console.log("Fetch data")
  // console.log(error)
  // console.log(loading)
  // console.log("arenaData", arenaData)
  // console.log("fetchedArenaData", fetchedArenaData)
  useEffect(() => {
    // if (arenaData === undefined && fetchedArenaData && !loading && !error) {
    if (fetchedArenaData && !loading && !error) {
      // console.log('useEffect')
      updateArenaData(fetchedArenaData)
    }
  }, [error, fetchedArenaData, loading, arenaData, updateArenaData])

  return null
}
