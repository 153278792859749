import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { ROUTER_ADDRESS } from '../constants'
import { ChainId, Percent, Token, CurrencyAmount, Fraction, Currency } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { TokenAddressMap } from '../state/lists/hooks'
import PicDai from '../assets/images/dai.png'
import PicUSDC from '../assets/images/usdc.png'
import PicWBTC from '../assets/images/wbtc.png'
import PicETH from '../assets/images/eth.png'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.',
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}etherscan.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export const currentTimestamp = () => new Date().getTime()

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

const ONE = new Fraction(1, 1)
export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: Percent): [JSBI, JSBI] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE)) throw new Error('Unexpected slippage')
  return [value.multiply(ONE.subtract(slippage)).quotient, value.multiply(ONE.add(slippage)).quotient]
}
// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(tokenAddressMap: TokenAddressMap, token?: Token): boolean {
  return Boolean(token?.isToken && tokenAddressMap[token.chainId as ChainId]?.[token.address])
}

export function feeTierPercent(fee: number): string {
  return (fee / 10000).toPrecision(1) + '%'
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

/**
 * Returns chain id if chain from chainId supports WETH
 * if not, return undefined
 * @param chainId
 */
export function supportedChainId(chainId: number): ChainId | undefined {
  if (chainId in ChainId) {
    return chainId
  }
  return undefined
}

export const getTokenIcon = (token: string) => {
  switch (token) {
    case 'DAI': 
      return PicDai
    case 'USDC': 
      return PicUSDC
    case 'WBTC': 
      return PicWBTC
    case 'ETH': 
      return PicETH
    case 'WETH': 
      return PicETH
    default:
      return ''
  }
}

export const getPricesFromCoingecko = async () => {
  const coins: any = {
    "dai": 'dai',
    "usd-coin": 'usdc',
    "wrapped-bitcoin": 'wbtc',
    "bitcoin": 'btc',
    "ethereum": 'eth',
    "weth": 'weth',
  }
  const result = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=dai,bitcoin,ethereum,usd-coin,weth,wrapped-bitcoin&vs_currencies=usd')
    .then(res => res.json())
    .then(res => {
      const prices = Object.keys(res).map(key => {
        return {
          symbol: coins[key],
          price: res[key].usd
        }
      })
      return prices
    })
    .catch(err => {return []})
  return result
}

export const getCoinPrice = (prices: any, symbol: string) => {
  if (prices && Array.isArray(prices) && prices.length) {
    const p = prices.find(f => f.symbol === symbol.toLowerCase())
    return p ? p.price : 0
  }
}