import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import { DarkGreyCard, GreyBadge } from 'components/Card'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { formatDollarAmount, formatAmount, formatSplitAmount } from 'utils/numbers'
import { getCoinPrice } from 'utils/index'
import { PoolData } from 'state/pools/reducer'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { feeTierPercent } from 'utils'
import { Label, ClickableText } from 'components/Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import { POOL_HIDE } from '../../constants/index'
import useTheme from 'hooks/useTheme'
import { usePrices } from 'state/price/hooks'
import { Battle } from 'state/battle/reducer'
import CurrencyLogo from 'components/CurrencyLogo'
import PicDai from '../../assets/images/dai.png'
import PicSpear from '../../assets/images/spear.png'
import PicShield from '../../assets/images/shield.png'
import toTopPic from '../../assets/svg/totop.svg'
import toBipartitePic from '../../assets/svg/tobipartite.svg'
import toBottomPic from '../../assets/svg/tobottom.svg'
import toStrikePic from '../../assets/svg/tostrike.svg'
import Tooltip from 'components/Tooltip'
import PicQ from '../../assets/images/q.png'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
  border: 1px double transparent;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border-radius: 6px;
  background-image: linear-gradient(rgb(18, 26, 39), rgb(18, 26, 39)), linear-gradient(rgb(53, 56, 72), rgb(106, 112, 139)) !important;
`

const TableBox = styled(AutoColumn)`
  max-height: 574px;
  overflow: auto;
  padding-right: 10px;
  &::-webkit-scrollbar { 
    width: 4px;
  }
  &::-webkit-scrollbar-thumb { 
    background: #6A708B;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track { 
    background: #121a27;
    border-radius: 10px;
    margin: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #373d57;
  }
  &::-webkit-scrollbar-corner: {
    background: transparent;
  }
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  //   align-items: center;
  align-items: flex-start;
  grid-template-columns: 10px 1fr repeat(6, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px repeat(3, 1fr) 1.5fr;
    & :nth-child(4) {
      display: none;
    }
    & :nth-child(5) {
      display: none;
    }
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 20px 1.5fr 1fr 1.5fr;
    & :nth-child(7) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 20px 2.5fr repeat(1, 1fr);
    & :nth-child(3) {
      display: none;
    }
  }
`

const HeaderGrid = styled(ResponsiveGrid)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-image: linear-gradient(rgb(18, 26, 39), rgb(18, 26, 39)), linear-gradient(rgb(53, 56, 72), rgb(106, 112, 139)) !important;
`

const LinkButton = styled.button`
  padding: 0 8px;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  font-size: 14px;
  height: 24px;
  width: 70px;
  box-shadow: 0 0 12px rgb(62 176 253 / 65%) inset, 0 0 8px rgb(62 176 253 / 80%);
  text-transform: none;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to right, #06DFAF, #0186EF);
    boxShadow: 0 0 12px rgb(62 176 253 / 65%) inset, 0 0 8px rgb(62 176 253 / 80%);
  };
`

const RolloverValueWrapper = styled.div`
  display: flex
  span {
    margin-left: 10px
  }
`
const TooltipContent = styled.div`
  p {
    display: flex;
  }
  img {
    margin-right: 10px;
  }
`

const SORT_FIELD = {
  // feeTier: 'feeTier',
  volumeUSD: 'volumeUSD',
  liquidityUSD: 'liquidityUSD',
  strikePrice: 'strikePrice',
  spearPrice: 'spearPrice',
  shieldPrice: 'shieldPrice',
  spearSold: 'spearSold',
  shieldSold: 'shieldSold',
  liquidityCollateral: 'liquidityCollateral',
  strikePriceUnder: 'strikePriceUnder',
  strikePriceOver: 'strikePriceOver',
  price: 'price'
  // volumeUSDWeek: 'volumeUSDWeek',
}

const StrikePrice = ({
  settleType,
  strikePrice,
  strikePriceOver,
  strikePriceUnder,
}: {
  settleType: number
  strikePrice: number
  strikePriceOver: number
  strikePriceUnder: number
}) => {
  if (settleType === 0) {
    return (
      <div>
        {formatSplitAmount(strikePriceUnder)}-{formatSplitAmount(strikePriceOver)}
      </div>
    )
  } else if (settleType === 1) {
    return <div>{formatSplitAmount(strikePriceOver)}</div>
  } else if (settleType === 2) {
    return <div>{formatSplitAmount(strikePriceUnder)}</div>
  } else if (settleType === 3) {
    return <div>{formatSplitAmount(strikePrice)}</div>
  } else {
    return <div>error</div>
  }
}

const RolloverValue = ({ settleType, settleValue }: { settleType: number; settleValue: number }) => {
  if (settleType === 3) {
    return <RolloverValueWrapper>
      <img width="22px" src={toStrikePic} alt="toStrikePic" />
    </RolloverValueWrapper>
  } else if (settleType === 0) {
    return <RolloverValueWrapper><img width="22px" src={toBipartitePic} alt="toBipartitePic" /><span>{(settleValue*100).toFixed()}%</span></RolloverValueWrapper>
  } else if (settleType === 1) {
    return <RolloverValueWrapper><img width="22px" src={toTopPic} alt="toTopPic" /><span>{(settleValue*100).toFixed()}%</span></RolloverValueWrapper>
  } else if (settleType === 2) {
    return <RolloverValueWrapper><img width="22px" src={toBottomPic} alt="toBottomPic" /><span>{(settleValue*100).toFixed()}%</span></RolloverValueWrapper>
  } else {
    return <RolloverValueWrapper>error</RolloverValueWrapper>
  }
}

const linkApp = {
  'dev': 'https://dev.divergence-protocol.com/',
  'alpha': 'https://alpha.divergence-protocol.com/',
  'beta': 'https://beta.divergence-protocol.com/',
  'prod': 'https://app.divergence-protocol.com/',
}

// const DataRow = ({ poolData, index }: { poolData: PoolData; index: number }) => {
const DataRow = ({ poolData, index }: { poolData: Battle; index: number}) => {
  const prices = usePrices()
  const price = useMemo(() => {
    return getCoinPrice(prices, poolData.collateralSymbol)
  }, [prices, poolData])
  return (
    <ResponsiveGrid>
      <Label fontWeight={400} paddingTop="10px">{index + 1}</Label>
      <Label fontWeight={400} padding="10px 0 3px" marginLeft="-5px">
        <RowFixed>
          {/* <DoubleCurrencyLogo address0={poolData.token0.address} address1={poolData.token1.address} /> */}
          <TYPE.label ml="8px">
            {/* {poolData.token0.symbol}/{poolData.token1.symbol} */}
            {/* {poolData.underlying}-USD */}
            {/* <div>2.0</div> */}
            {/* {poolData.settleType} */}
            <StrikePrice
              settleType={poolData.settleType}
              strikePrice={poolData.strikePrice}
              strikePriceOver={poolData.strikePriceOver}
              strikePriceUnder={poolData.strikePriceUnder}
            />
          </TYPE.label>
          {/* <GreyBadge ml="10px" fontSize="14px">
              {feeTierPercent(poolData.feeTier)}
            </GreyBadge> */}
        </RowFixed>
      </Label>
      <Label fontWeight={400} padding="10px 0 3px">
        {/* {formatDollarAmount(poolData.settleValue)} */}
        <RolloverValue settleType={poolData.settleType} settleValue={poolData.settleValue} />
      </Label>
      <Label end={1} fontWeight={400} padding="9px 0 2px" minWidth="170px">
        {/* {formatDollarAmount(poolData.tvlUSD)} */}
        <RowFixed>
          {/* <DoubleCurrencyLogo address0={poolData.token0.address} address1={poolData.token1.address} /> */}
          <TYPE.label display="flex">
            {/* {poolData.token0.symbol}/{poolData.token1.symbol} */}
            {/* <CurrencyLogo address={poolData.collateral} /> */}
            {/* <img width="16px" src={PicDai} /> */}
            <div style={{minWidth: '80px'}}>
              <img width="20px" src={PicSpear} style={{position: 'relative', top: '3px', left: '-8px'}}/>
              { formatAmount(poolData.spearPrice) }
            </div>
             {/* /  */}
            <div style={{minWidth: '80px'}}>
              <img width="20px" src={PicShield} style={{position: 'relative', top: '3px', marginRight: '5px'}}/> 
              {formatAmount(poolData.shieldPrice)}
            </div>
            
          </TYPE.label>
        </RowFixed>
      </Label>
      <Label end={1} fontWeight={400} padding="10px 0 3px">
        {formatAmount(poolData.spearSold)}/{formatAmount(poolData.spearNotSold)}
      </Label>
      <Label end={1} fontWeight={400} padding="10px 0 3px">
        {formatAmount(poolData.shieldSold)}/{formatAmount(poolData.shieldNotSold)}
      </Label>
      <Label end={1} fontWeight={400} padding="10px 0 3px">
        {/* {formatDollarAmount(poolData.liquidityUSD)} */}
        {formatDollarAmount(poolData.liquidityCollateral * price)}
      </Label>
      
      <Label end={1} fontWeight={400} padding="10px 0 3px" minWidth="170px">
        {/* @ts-ignore */}
        <a href={`${linkApp[process.env.REACT_APP_TARGET_ENV]}trade/${poolData.address}/0/0`}><LinkButton style={{marginRight: '10px'}}>Trade</LinkButton></a>
        {/* @ts-ignore */}
        <a href={`${linkApp[process.env.REACT_APP_TARGET_ENV]}lp/${poolData.address}/add`}><LinkButton>+/-</LinkButton></a>
      </Label>
    </ResponsiveGrid>
  )
}

const MAX_ITEMS = 10000

export default function BattleTable({ poolDatas, maxItems = MAX_ITEMS, priceType }: { poolDatas: Battle[]; maxItems?: number, priceType: string  }) {
  // theming
  const theme = useTheme()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.liquidityCollateral)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [tooltipStatus, setTooltipStatus] = useState(false)
  const [tooltipStatusSpear, setTooltipStatusSpear] = useState(false)
  const [tooltipStatusShield, setTooltipStatusShield] = useState(false)
  useEffect(() => {
    let extraPages = 1
    if (poolDatas.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(poolDatas.length / maxItems) + extraPages)
  }, [maxItems, poolDatas])

  const sortedPools = useMemo(() => {
    return poolDatas
      ? poolDatas
          .filter((x) => !!x && !POOL_HIDE.includes(x.address))
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof Battle] > b[sortField as keyof Battle]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [maxItems, page, poolDatas, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!poolDatas) {
    return <Loader />
  }

  const openTooltip = () => setTooltipStatus(true)
  const closeTooltip = () => {
    setTimeout(() => {
      setTooltipStatus(false)
    }, 3000)
  }

  const openTooltipSpear = () => setTooltipStatusSpear(true)
  const closeTooltipSpear = () => {
    setTimeout(() => {
      setTooltipStatusSpear(false)
    }, 3000)
  }

  const openTooltipShield = () => setTooltipStatusShield(true)
  const closeTooltipShield = () => {
    setTimeout(() => {
      setTooltipStatusShield(false)
    }, 3000)
  }

  return (
    <Wrapper>
      {/* {sortedPools.length > 0 ? ( */}
        <TableBox gap="8px">
          <HeaderGrid>
            <Label style={{ padding: "5px 0" }} color={theme.text3}>#</Label>
            <ClickableText style={{ padding: "5px 0" }} color={theme.text3} onClick={() => handleSort(SORT_FIELD.price)}>
              Strike Price {arrow(SORT_FIELD.price)}
            </ClickableText>
            <ClickableText style={{ padding: "5px 0" }} color={theme.text3}>
              Strike Update
              <Tooltip 
                text={
                  <TooltipContent>
                    <p>After each expiration, unclaimed liquidity in the pool will automatically fund a new market with updated strike prices:</p>
                    <p>
                      <img width="16px" src={toStrikePic} alt="Strike" />
                      strike price will remain unchanged. 
                    </p>
                    <p>
                      <img width="16px" src={toTopPic} alt="top" />
                      strike price is set above settlement price by a certain percentage.
                    </p>
                    <p>
                      <img width="16px" src={toBottomPic} alt="bottom" />
                      strike price is set below settlement price by a certain percentage.
                    </p>
                    <p>
                      <img width="16px" src={toBipartitePic} alt="bipartite" />
                      strike price is set around settlement price by a certain percentage. 
                    </p>
                  </TooltipContent>
                } 
                show={tooltipStatus}
                >
                <img onClick={openTooltip} onMouseEnter={openTooltip} onMouseLeave={closeTooltip} width="14px" src={PicQ} alt="?" style={{position: 'relative', top: '2px', left: '4px'}}/>
              </Tooltip>
            </ClickableText>
            <ClickableText minWidth="170px" style={{ padding: "5px 20px 5px 0px" }} color={theme.text3} end={1}>
              Option Price
            </ClickableText>
            <ClickableText style={{ padding: "5px 0" }} color={theme.text3} end={1} onClick={() => handleSort(SORT_FIELD.spearSold)}>
              Spear OI/Size {arrow(SORT_FIELD.spearSold)}
              <Tooltip 
                text={
                  <TooltipContent>
                    <p>1）Open interest: total number of outstanding Spear (binary call) positions held by traders that have not been settled.</p>
                    <p>2）Size: the number of Spear (binary call) available for purchase in the pool. </p>
                  </TooltipContent>
                } 
                show={tooltipStatusSpear}
                >
                <img onClick={openTooltipSpear} onMouseEnter={openTooltipSpear} onMouseLeave={closeTooltipSpear} width="14px" src={PicQ} alt="?" style={{position: 'relative', top: '2px', left: '4px'}}/>
              </Tooltip>
            </ClickableText>
            <ClickableText style={{ padding: "5px 0" }} color={theme.text3} end={1} onClick={() => handleSort(SORT_FIELD.shieldSold)}>
              Shield OI/Size {arrow(SORT_FIELD.shieldSold)}
              <Tooltip 
                text={
                  <TooltipContent>
                    <p>1）Open interest: total number of outstanding Shield (binary put) positions held by traders that have not been settled.</p>
                    <p>2）Size: the number of Shield (binary put) available for purchase in the pool. </p>
                  </TooltipContent>
                } 
                show={tooltipStatusShield}
                >
                <img onClick={openTooltipShield} onMouseEnter={openTooltipShield} onMouseLeave={closeTooltipShield} width="14px" src={PicQ} alt="?" style={{position: 'relative', top: '2px', left: '4px'}}/>
              </Tooltip>
            </ClickableText>
            <ClickableText style={{ padding: "5px 0" }} color={theme.text3} end={1} onClick={() => handleSort(SORT_FIELD.liquidityCollateral)}>
              Liquidity {arrow(SORT_FIELD.liquidityCollateral)}
            </ClickableText>
            
            <ClickableText style={{ padding: "5px 0" }} color={theme.text3} end={1}  minWidth="170px">
              &nbsp;
            </ClickableText>
          </HeaderGrid>
          <Break />
          {sortedPools.length > 0 ?
            sortedPools.map((poolData, i) => {
              if (poolData) {
                return (
                  <React.Fragment key={i}>
                    <DataRow index={(page - 1) * MAX_ITEMS + i} poolData={poolData} />
                    <Break />
                  </React.Fragment>
                )
              }
              return null
            }) : <div style={{textAlign: 'center', padding: '60px 0'}}>
              No Available Pools
            </div>
          }
          {/* {sortedPools.length > 0 && 
            <PageButtons>
              <div
                onClick={() => {
                  setPage(page === 1 ? page : page - 1)
                }}
              >
                <Arrow faded={page === 1 ? true : false}>←</Arrow>
              </div>
              <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
              <div
                onClick={() => {
                  setPage(page === maxPage ? page : page + 1)
                }}
              >
                <Arrow faded={page === maxPage ? true : false}>→</Arrow>
              </div>
            </PageButtons>
          } */}
        </TableBox>
    </Wrapper>
  )
}
