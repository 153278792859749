import { createReducer } from '@reduxjs/toolkit'
import { ChartDayData } from 'types'
import { currentTimestamp } from 'utils'
import { updateBattles } from './actions'

export interface Battle {
  address: string
  collateral: string
  collateralSymbol: string
  underlying: string
  volumeUSD: number
  liquidityUSD: number
  liquidityCollateral: number
  peroidType: number
  settleType: number
  settleValue: number
  strikePrice: number
  strikePriceOver: number
  strikePriceUnder: number
  spearPrice: number
  shieldPrice: number
  spearSold: number
  spearNotSold: number
  shieldSold: number
  shieldNotSold: number
  createAt: number
  volumeUSD24H: number
  volumeUSD7D: number
  price: number
}

export interface BattleDayHour {
  addr: string
  date: number
  volumeUSD: number
  liquidityUSD: number
}

export interface BattleData {
  data: Battle[]
  dayData: BattleDayHour[]
  hourData: BattleDayHour[]
}

export interface BattleState {
  readonly data: BattleData | undefined
  readonly lastUpdated: number | undefined
}

export const initialState: BattleState = {
  data: undefined,
  lastUpdated: undefined,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(updateBattles, (state, { payload: { data } }) => {
    state.data = data
    state.lastUpdated = currentTimestamp()
  })
})
