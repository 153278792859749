import dayjs from 'dayjs'
import { ethers } from 'ethers'
import OracleAbi from './abis/Oracle.json'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
export const INFURA_URL = 'https://kovan.infura.io/v3/082a3def8d524dfc9f43dad330a2a399'

export const INFURA_KEY = '082a3def8d524dfc9f43dad330a2a399'
export const ORACLE_ADDR = '0x7Ad23ff84d6840867f6EbBb95e1b00444910f027'

export async function getOracle() {
  const provider = new ethers.providers.InfuraProvider('kovan', INFURA_KEY)
  const oracle = new ethers.Contract(ORACLE_ADDR, OracleAbi, provider)
  const tsDay: string[] = (await oracle.getRoundTS(0)).map((ts: any) => {
    return ts.toNumber()//.dayjs.unix(ts.toNumber()).utc().format('D MMM YYYY HH:mm')
  })
  // console.log(start.toNumber(), start, start)
  const tsWeek: string[] = (await oracle.getRoundTS(1)).map((ts: any) => {
    return ts.toNumber()// dayjs.unix(ts.toNumber()).utc().format('D MMM YYYY HH:mm')
  })
  const tsMon: string[] = (await oracle.getRoundTS(2)).map((ts: any) => {
    return ts.toNumber()// dayjs.unix(ts.toNumber()).utc().format('D MMM YYYY HH:mm')
  })
  return [tsDay, tsWeek, tsMon]
}

export async function getBTCPrice(coin: string) {
  const resp = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=usd`)
  const data = await resp.json()
  return data[coin]['usd']
}
