import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { updateBattles } from './actions'
import { BattleData } from './reducer'

export function useBattleData(): [BattleData | undefined, (battleData: BattleData) => void] {
  const battleData = useSelector((state: AppState) => state.battle.data)
  const dispatch = useDispatch<AppDispatch>()
  const setBattleData: (battleData: BattleData) => void = useCallback(
    (data: BattleData) => dispatch(updateBattles({ data })),
    [dispatch]
  )
  return [battleData, setBattleData]
}

export function useBattleLoad() {
  const battleDataLoad = useSelector((state: AppState) => state.battle.data?.data)
  return !!battleDataLoad?.length
}
