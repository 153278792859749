import { ApolloClient, InMemoryCache } from '@apollo/client'

const URI = {
  'dev': 'https://api.studio.thegraph.com/query/1512/dev-divergence/v0.8',
  'alpha': 'https://api.thegraph.com/subgraphs/name/alexis-eth/divergence',
  'beta': 'https://api.studio.thegraph.com/query/1512/dev-divergence/beta0.3',
  'prod': 'https://api.thegraph.com/subgraphs/name/alexis-eth/divergence'
}

export const client = new ApolloClient({
  // uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  // @ts-ignore
  uri: URI[process.env.REACT_APP_TARGET_ENV],
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const healthClient = new ApolloClient({
  uri: 'https://api.thegraph.com/index-node/graphql',
  cache: new InMemoryCache(),
})

export const blockClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
})
