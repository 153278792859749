import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import { DarkGreyCard, GreyBadge } from 'components/Card'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { formatDollarAmount } from 'utils/numbers'
import { PoolData } from 'state/pools/reducer'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { feeTierPercent } from 'utils'
import { Label, ClickableText } from 'components/Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import { POOL_HIDE } from '../../constants/index'
import useTheme from 'hooks/useTheme'
import { Battle } from 'state/battle/reducer'
import CurrencyLogo from 'components/CurrencyLogo'
import { getTokenIcon } from 'utils'
import { getFullNum } from 'utils/numbers'
import { utils } from 'ethers'
import BTCPic from 'assets/images/btc.png'
import ETHPic from 'assets/images/eth.png'
import { func } from 'prop-types'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 15px 1.5fr repeat(4, 1fr);

  // @media screen and (max-width: 900px) {
  //   grid-template-columns: 15px 1.5fr repeat(4, 1fr);
  //   & :nth-child(3) {
  //     display: none;
  //   }
  // }

  @media screen and (max-width: 500px) {
    grid-template-columns: 15px 1.5fr repeat(3, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 15px repeat(3, 1fr);
    & :nth-child(3) {
      display: none;
    }
    & :nth-child(6) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const UnderlyingIcon = styled.img`
  margin-right: 10px;
  position: relative;
  top: 1px;
`

const SORT_FIELD = {
  // feeTier: 'feeTier',
  volumeUSD: 'volumeUSD',
  liquidityUSD: 'liquidityUSD',
  volumeUSD24H: 'volumeUSD24H',
  volumeUSD7D: 'volumeUSD7D',
  // volumeUSDWeek: 'volumeUSDWeek',
}
// @ts-ignore
const returnIcon = (name: string) => {
  switch (name) {
    case 'BTC':
      return BTCPic
    case 'ETH':  
      return ETHPic
  }
}

// const DataRow = ({ poolData, index }: { poolData: PoolData; index: number }) => {
const DataRow = ({ poolData, index }: { poolData: Battle; index: number }) => {
  // console.log("PoolTable::dataRow", poolData)
  return (
    <LinkWrapper to={`/pools/0/${poolData.underlying}/0/${poolData.collateralSymbol}`}>
      <ResponsiveGrid>
        <Label fontWeight={400}>{index + 1}</Label>
        <Label fontWeight={400}>
          <RowFixed>
            {/* <DoubleCurrencyLogo address0={poolData.token0.address} address1={poolData.token1.address} /> */}
            <TYPE.label>
              {/* {poolData.token0.symbol}/{poolData.token1.symbol} */}
              <UnderlyingIcon width="16px" src={returnIcon(poolData.underlying)} alt="icon" />
              {poolData.underlying}-USD
            </TYPE.label>
            {/* <GreyBadge ml="10px" fontSize="14px">
              {feeTierPercent(poolData.feeTier)}
            </GreyBadge> */}
          </RowFixed>
        </Label>
        <Label end={0} fontWeight={400}>
          {/* {formatDollarAmount(poolData.tvlUSD)} */}
          <RowFixed>
            {/* <DoubleCurrencyLogo address0={poolData.token0.address} address1={poolData.token1.address} /> */}
            <TYPE.label>
              {/* {poolData.token0.symbol}/{poolData.token1.symbol} */}
              {/* <CurrencyLogo address={poolData.collateral} /> */}
              <img width="16px" src={ getTokenIcon(poolData.collateralSymbol) } style={{ marginRight: '10px', position: 'relative', top: '2px' }} />
              {/* {
                poolData.collateralSymbol === 'USDC' && <img width="16px" src={PicDai} style={{ marginRight: '10px', position: 'relative', top: '2px' }} />
              } */}
              {poolData.collateralSymbol}
            </TYPE.label>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(poolData.liquidityUSD)}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(poolData.volumeUSD24H)}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(poolData.volumeUSD7D)}
        </Label>
      </ResponsiveGrid>
    </LinkWrapper>
  )
}

const MAX_ITEMS = 10

export default function PoolTable({ poolDatas, maxItems = MAX_ITEMS }: { poolDatas: Battle[]; maxItems?: number }) {
  // theming
  const theme = useTheme()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.liquidityUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (poolDatas.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(poolDatas.length / maxItems) + extraPages)
  }, [maxItems, poolDatas])

  // console.log(poolDatas, '-------------')

  const sortedPools = useMemo(() => {
    return poolDatas
      ? poolDatas
          .filter((x) => !!x && !POOL_HIDE.includes(x.address))
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof Battle] > b[sortField as keyof Battle]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [maxItems, page, poolDatas, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!poolDatas) {
    return <Loader />
  }

  return (
    <Wrapper>
      {sortedPools.length > 0 ? (
        <AutoColumn gap="16px">
          <ResponsiveGrid>
            <Label color={theme.text3}>#</Label>
            <ClickableText color={theme.text3}>
              Pool
            </ClickableText>
            <ClickableText color={theme.text3} end={0}>
              Collateral
            </ClickableText>
            <ClickableText color={theme.text3} end={1} onClick={() => handleSort(SORT_FIELD.liquidityUSD)}>
              Liquidity {arrow(SORT_FIELD.liquidityUSD)}
            </ClickableText>
            <ClickableText color={theme.text3} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSD24H)}>
              Volume 24H{arrow(SORT_FIELD.volumeUSD24H)}
            </ClickableText>
            <ClickableText color={theme.text3} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSD7D)}>
              Volume 7D{arrow(SORT_FIELD.volumeUSD7D)}
            </ClickableText>
          </ResponsiveGrid>
          <Break />
          {sortedPools.map((poolData, i) => {
            if (poolData) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} poolData={poolData} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
