// @ts-nocheck
import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import styled from 'styled-components'
import BTCLOGO from '../../assets/images/btc.png'
import ETHLOGO from '../../assets/images/eth.png'
import DAILOGO from '../../assets/images/dai.png'
import USDCLOGO from '../../assets/images/usdc.png'
import WBTCLOGO from '../../assets/images/wbtc.png'
import PicArrowDown from '../../assets/svg/arrow_down.svg'
import React from 'react'
import BattleTable from './BattleTable'
import { useBattleData } from 'state/battle/hooks'
import { Battle, BattleData } from 'state/battle/reducer'
import { getBTCPrice, getOracle } from 'constants/infural'
import Countdown from 'react-countdown'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { getTokenIcon } from 'utils'
dayjs.extend(utc)
// import { getOracle } from 'constants/infural'

const BattleWrapper = styled.div`
  @media screen and (max-width: 1200px) {
    width: 90%;
  }
`
const Breadcrumbs = styled.div`
  font-size: 21px;
`
const UnderLying = styled.div`
  width: 219px;
  height: 49px;
  line-height: 49px;
  background: rgba(24,31,43,0.5);
  border-radius: 32px;
  font-size: 21px;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  margin-left: 30px;
  span {
    top: -6px;
    left: -30px;
    width: 60px;
    border: 1px solid #fff;
    height: 60px;
    display: block;
    position: absolute;
    background: rgba(24,31,43,0.5);
    box-shadow: 0px 0px 14px rgb(62 176 253 / 65%) inset, 0 0 30px rgb(62 176 253 / 80%);
    padding-top: 14px;
    border-radius: 45px;
  }
`
const SelectMain = styled.div`
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  select {
    outline-style: none;
    width: 359px;
    margin-right: 120px;
    background: transparent;
    font-size: 17px;
    color: #fff;
    border-radius: 6px;
    padding-left: 10px;
    height: 54px;
    border: 1px solid #6a708b;
    option {
      background: #f00;
      color: #fff;
    }
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`
const Cycle = styled.div`
  display: flex;
  margin-top: -1px;
  > div {
    cursor: pointer;
    margin-left: 29px;
    width: 200px;
    box-shadow: 0 0 12px rgb(62 176 253 / 65%) inset, 0 0 15px rgb(62 176 253 / 80%);
    border: 1px solid #fff;
    border-radius: 6px;
    padding: 3px 6px;
    text-align: center;
    font-weight: 500;
    &.active {
      background: linear-gradient(90deg, rgba(6, 223, 175, 0.6) 0%, rgba(1, 134, 239, 0.6) 100%);
    }
    > p {
      font-size: 16px;
      margin: 5px 0;
      line-height: 20px;
    }
    > p:last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 1000px) {
    justify-content: space-between;
    > div {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 700px) {
    justify-content: space-between;
    > div {
      flex: 0 0 calc(33.333333% - 10px);
    }
  }
`
const Text = styled.p`
  font-size: 16px;
  text-align: right;
  margin: 0;
  margin-bottom: 6px;
  font-weight: 500;
`
const Title = styled.h3`
  font-size: 19px;
  margin-bottom: 20px;
  font-weight: 500;
  margin-top: -25px;
`
const Options = styled.div`
  background: #181f2b;
  font-size: 14px;
  border: 1px solid #6a708b;
  border-top: none;
  position: absolute;
  width: 100%;
  top: 61px;
  z-index: 2;
  border-radius: 0 0 6px 6px;
  div {
    padding: 10px;
    cursor: initial;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    &:hover {
      background: #111b29;
    }
  }
  div:first-child {
    padding-top: 15px
  }
  img {
    width: 23px;
    position: relative;
    margin-right: 10px;
    top: 6px;
  }
`
const SelectFlex = styled.div`
  display: flex;
  > div:nth-child(1) {
    margin-right: 30px;
  }
  @media screen and (max-width: 1000px) {
    justify-content: space-between;
    margin-bottom: 20px;
    > div:nth-child(1) {
      width: 45%;
      margin-right: 0;
    }
    > div:nth-child(2) {
      width: 45%;
    }
  }
`
const Select = styled.div`
  position: relative;
`
const SelectBox = styled.div`
  width: 260px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #6a708b;
  position: relative;
  z-index: 2;
  padding-left: 10px;
  line-height: 64px;
  cursor: pointer;
  font-weight: 500;
  img {
    width: 23px;
    position: relative;
    margin-right: 10px;
    top: 6px;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`

const Arrow = styled.img`
  width: 10px !important;
  float: right;
  margin-top: 17px;
  &.open {
    transform: rotate(180deg);
  }
`
const options = []
if (process.env.REACT_APP_TARGET_ENV === 'dev' || process.env.REACT_APP_TARGET_ENV === 'alpha'){
  options.push({
    name: 'DAI',
    icon: DAILOGO,
    status: true
  }, {
    name: 'USDC',
    icon: USDCLOGO,
    status: true
  }, {
    name: 'WBTC',
    icon: WBTCLOGO,
    status: true
  }, {
    name: 'WETH',
    icon: ETHLOGO,
    status: true
  })
} else if (process.env.REACT_APP_TARGET_ENV === 'beta') {
  options.push({
    name: 'DAI',
    icon: DAILOGO,
    status: true
  })
}

const strikeModeOptions = [{
  name: 'Single Strike',
}, {
  name: 'Range Strike',
}]
export default function BattleInfo() {
  const [battleData] = useBattleData()
  const routerParams = useParams()
  const multiBattle = battleData?.data ? battleData.data : []
  const [totalBattleData, setTotalBattleData] = useState<BattleData>()
  const [currentBattles, setCurrentBattles] = useState<Battle[]>([])
  const [currentDateString, setCurentDateString] = useState<string[][]>()
  const [currentDateUnix, setCurentDateUnix] = useState<number>()
  const [btcPrice, setBTCPrice] = useState(0)
  const [pType, setPType] = useState(Number(routerParams.peroidType))
  // console.log(routerParams)

  const [selectVal, setSelectVal] = useState({
    name: routerParams.collateral,
    icon: getTokenIcon(routerParams.collateral),
    status: true,
  })

  const [strikeModeSelectVal, setStrikeModeSelectVal] = useState({
    name: routerParams.strikeMode === '0' ? 'Single Strike' : 'Range Strike',
  })
  
  const [optionsStatus, setOptionsStatus] = useState(false)
  const [strikeOptionsStatus, setStrikeOptionsStatus] = useState(false)
  const selectHandler = (key: number, e: any) => {
    setSelectVal(options[key])
    setOptionsStatus(false)
    const newBattle = battleData?.data.filter((f) => {
      return f.collateralSymbol === options[key].name && f.peroidType == pType && f.underlying === routerParams.underlying
    })
    if (newBattle) {
        (newBattle)
    }
  }
  const strikeModeSelectHandler = (key: number, e: any) => {
    setStrikeOptionsStatus(false)
    if (strikeModeSelectVal === strikeModeOptions[key]) return
    setStrikeModeSelectVal(strikeModeOptions[key])
    const newBattle = battleData?.data.filter((f) => {
      return f.collateralSymbol === strikeModeOptions[key].name && f.peroidType == pType && f.underlying === routerParams.underlying
    })
    if (newBattle) {
      setCurrentBattles(newBattle)
    }
  }

  

  const peroidChangeHandler = useCallback((pt: number) => {
    setPType(pt)
    if (currentDateString) {
      setCurentDateUnix(currentDateString[pt][1])
    }
    // console.log(battleData, 'page-----------peroidChangeHandler')
    const newBattle = battleData?.data.filter((b) => {
      if (strikeModeSelectVal.name === 'Single Strike') {
        if (b.settleType !== 0) {
          return b.peroidType === pt && b.collateralSymbol === selectVal.name && b.underlying === routerParams.underlying
        }
      } else {
        if (b.settleType === 0) {
          return b.peroidType === pt && b.collateralSymbol === selectVal.name && b.underlying === routerParams.underlying
        }
      }
      
    })
    if (newBattle) {
      setCurrentBattles(newBattle)
    }
  }, [battleData, currentDateString, selectVal.name, routerParams.underlying, strikeModeSelectVal.name])


  useEffect(() => {
    // console.log(battleData, 'battleData------------------page')
    peroidChangeHandler(pType)
  }, [battleData, pType, peroidChangeHandler])

  useEffect(() => {
    getOracle().then((d) => {
      setCurentDateString(d)
      setCurentDateUnix(d[0][1])
    })
    // peroidChangeHandler(0)
  }, [peroidChangeHandler])

  useMemo(() => {
    const newBattle = battleData?.data.filter((b) => {
      if (b.settleType !== 0) {
        return b.peroidType === pType && b.collateralSymbol === routerParams.collateral && b.underlying === routerParams.underlying
      }
    })
    if (newBattle) {
      setCurrentBattles(newBattle)
    }
  }, [battleData, pType, routerParams.collateral, routerParams.underlying])

  useEffect(() => {
    let coinName
    if (routerParams.underlying === 'BTC') {
      coinName = 'bitcoin'
    } else if (routerParams.underlying === 'ETH') {
      coinName = 'ethereum'
    }
    getBTCPrice(coinName).then((d) => {
      setBTCPrice(d)
    })
  }, [routerParams.underlying])
  
  return (
    <BattleWrapper>
      <Breadcrumbs>
        <NavLink to="/" style={{ textDecoration: 'none', color: '#fff' }}>Overview </NavLink>
        &nbsp; &gt; &nbsp; Battles
      </Breadcrumbs>
      <UnderLying>
        <span>
          { routerParams.underlying === 'BTC' && <img width="30px" alt="btc" src={BTCLOGO} /> }
          { routerParams.underlying === 'ETH' && <img width="30px" alt="btc" src={ETHLOGO} /> }
          
        </span>
        {routerParams.underlying}-USD
      </UnderLying>
      <SelectMain>
        <SelectFlex>
          <Select>
            <SelectBox onClick={() => setOptionsStatus(!optionsStatus)}>
              {selectVal.status ? (
                <>
                  <img src={selectVal.icon} alt="icon" />
                  {selectVal.name}
                </>
              ) : (
                <span style={{ color: '#6A708B' }}>{selectVal.name}</span>
              )}
              <Arrow className={optionsStatus ? 'open' : ''} src={PicArrowDown} alt="arrow" />
            </SelectBox>
            {optionsStatus && (
              <Options>
                {options.map((item, key) => {
                  return (
                    <div key={key} onClick={selectHandler.bind(this, key)}>
                      <img src={item.icon} alt="icon" />
                      <span>{item.name}</span>
                    </div>
                  )
                })}
              </Options>
            )}
          </Select>
          <Select>
          <SelectBox onClick={() => setStrikeOptionsStatus(!strikeOptionsStatus)}>
              <span>{strikeModeSelectVal.name}</span>
            <Arrow className={strikeOptionsStatus ? 'open' : ''} src={PicArrowDown} alt="arrow" />
          </SelectBox>
          {strikeOptionsStatus && (
            <Options>
              {strikeModeOptions.map((item, key) => {
                return (
                  <div key={key} onClick={strikeModeSelectHandler.bind(this, key)}>
                    <span>{item.name}</span>
                  </div>
                )
              })}
            </Options>
          )}
        </Select>
        </SelectFlex>
        <Cycle>
          <div
            onClick={() => {
              peroidChangeHandler(0)
            }}
            className={pType === 0 ? 'active' : ''}
          >
            <p>Daily</p>
            <p>{currentDateString ? dayjs.utc(currentDateString[0][1] * 1000).format('D MMM YYYY HH:mm') + " UTC" : ''}</p>
          </div>
          <div
            onClick={() => {
              peroidChangeHandler(1)
            }}
            className={pType === 1 ? 'active' : ''}
          >
            <p>Weekly</p>
            <p>{currentDateString ? dayjs.utc(currentDateString[1][1] * 1000).format('D MMM YYYY HH:mm') + " UTC" : ''}</p>
          </div>
          <div
            onClick={() => {
              peroidChangeHandler(2)
            }}
            className={pType === 2 ? 'active' : ''}
          >
            <p>Monthly</p>
            <p>{currentDateString ? dayjs.utc(currentDateString[2][1] * 1000).format('D MMM YYYY HH:mm') + " UTC" : ''}</p>
          </div>
        </Cycle>
      </SelectMain>
      <Text>
        Expires in: &nbsp; 
        {
          currentDateUnix > 0 && <Countdown date={(currentDateUnix * 1000)} renderer={
            props => <span>{
              // eslint-disable-next-line react/prop-types
              props.days > 0 ? (props.days + ' D ' + props.hours + ' H ' + props.minutes + ' M ' ) : (props.hours > 0 ? (props.hours + ' H ' + props.minutes + ' M '  + props.seconds + ' S ') : (props.minutes + ' M ' + props.seconds + ' S '))
            }</span>}/>
        }
       
      </Text>
      <Text>Underlying Price: ${btcPrice.toLocaleString()}</Text>

      <Title>Top Pools</Title>
      {/* <TopPoolsDynamics></TopPoolsDynamics> */}
      <BattleTable poolDatas={currentBattles} priceType={strikeModeSelectVal.name} />
    </BattleWrapper>
  )
}
