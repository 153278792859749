import styled, { createGlobalStyle } from 'styled-components'


export const Globalstyle = createGlobalStyle`
  ::-webkit-scrollbar : { 
    width: '4px'
  }
  ::-webkit-scrollbar-track : { 
    background: '#121a27';
    borderRadius: '2px'
  }
  ::-webkit-scrollbar-thumb : { 
    background: '#6A708B';
    borderRadius: '4px'
  }
  -webkit-scrollbar-thumb:hover :{ 
    background: '#373d57'
  }
`

export const PageWrapper = styled.div`
  width: 90%;
`

export const ThemedBackground = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-176vh);
`

export const ThemedBackgroundGlobal = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-150vh);
  display: none;
`
