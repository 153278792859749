import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { updateData } from './actions'
import { ArenaData } from './reducer'

export function useArenaData(): [ArenaData | undefined, (arenaData: ArenaData) => void] {
  const arenaData = useSelector((state: AppState) => state.arena.data)
  const dispatch = useDispatch<AppDispatch>()
  const setArenaData: (arenaData: ArenaData) => void = useCallback(
    (data: ArenaData) => {
      dispatch(updateData({ data }))
      // console.log("update redux data", data)
    },
    [dispatch]
  )
  return [arenaData, setArenaData]
}

// export function useArenaDayData() {}

// export function useArenaHourData() {}
