import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useRefresh from '../../hooks/useRefresh'
import { getPricesFromCoingecko } from '../../utils/index'
import { setPriceData } from './actions'

export default function Updater(): null {
    const dispatch = useDispatch()
    const { tenRefresh } = useRefresh()

    useEffect(() => {
        const fetchPrices = async () => {
            const result = await getPricesFromCoingecko()
            dispatch(setPriceData(result))
        }
        fetchPrices()
    }, [dispatch, tenRefresh])
    

    return null
}