import { useFetchBattleData } from 'data/battle'
import { useEffect } from 'react'
import { useBattleData } from './hooks'

export default function Updater(): null {
  const [battleData, updateBattleData] = useBattleData()
  // console.log('battle updater')
  const { data: fetchedBattleData, error, loading } = useFetchBattleData()
  if (error) {
    console.log(error)
  }
  // console.log("battleData", battleData)
  // console.log("fetchedBattleData", fetchedBattleData)
  useEffect(() => {
    if (fetchedBattleData && !loading && !error) {
      // console.log('battle effect,----------------------- updater', fetchedBattleData)
      updateBattleData(fetchedBattleData)
      
    }
  }, [error, fetchedBattleData, loading, battleData, updateBattleData])
  return null
}
