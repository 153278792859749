import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { ArenaData } from 'state/arena/reducer'
import { ChartDayData } from 'types'

export const ARENA_DATA = () => {
  const queryString = `
      query {
        arenas (first: 1000) {
          id
          battleCount
          totalVolumeUSD
          totalLiquidityUSD
        }
        arenaDayDatas (first: 1000) {
          date,
          volumeUSD,
          liquidityUSD
        }
        arenaHourDatas (first: 1000) {
          date
          volumeUSD
          liquidityUSD
        }
    }`
  return gql(queryString)
}

export interface ArenaResponse {
  arenas: {
    totalVolumeUSD: string
    totalLiquidityUSD: string
  }[]
  arenaDayDatas: {
    date: number
    volumeUSD: string
    liquidityUSD: string
  }[]
  arenaHourDatas: {
    date: number
    volumeUSD: string
    liquidityUSD: string
  }[]
}

export function useFetchArenaData(): {
  loading: boolean
  error: boolean
  data: ArenaData | undefined
  startPolling: (pollInterval: number) => void
} {
  const { loading, error, data, startPolling, stopPolling} = useQuery<ArenaResponse>(ARENA_DATA(), {pollInterval: 2000})
  // console.log("LLLLLL")

  // console.log(data, '-----')
  if (error) {
    console.log(`fetchData Error ${error}`)
  }
  const anyError = Boolean(error)
  const anyLoading = Boolean(loading)
  const parsedArena = data?.arenas?.[0]
  const parsedDayDatas = data?.arenaDayDatas
  // console.log(1111)
  // console.log(data)
  // console.log(parsedDayDatas)
  const parseHourDatas = data?.arenaHourDatas
  const formattedData: ArenaData | undefined = useMemo(() => {
    if (anyError || anyLoading) {
      return undefined
    }
    const liquidityUSD = parsedArena ? parseFloat(parsedArena.totalLiquidityUSD) : 0
    const volumeUSD = parsedArena ? parseFloat(parsedArena.totalVolumeUSD) : 0

    let dayDatas: ChartDayData[] = []
    if (parsedDayDatas) {
      dayDatas = parsedDayDatas.reduce((pV: any, cV: any) => {
        const dayData: ChartDayData = {
          date: cV.date,
          volumeUSD: parseFloat(cV.volumeUSD),
          tvlUSD: parseFloat(cV.liquidityUSD),
        }
        pV.push(dayData)
        return pV
      }, [])
    }

    let hourDatas: ChartDayData[] = []
    if (parseHourDatas) {
      hourDatas = parseHourDatas.reduce((pV: any, cV: any) => {
        const hourData: ChartDayData = {
          date: cV.date,
          volumeUSD: parseFloat(cV.volumeUSD),
          tvlUSD: parseFloat(cV.liquidityUSD),
        }
        pV.push(hourData)
        return pV
      }, [])
    }
    // console.log(333)
    // console.log(dayDatas)
    // console.log(hourDatas)
    return {
      volumeUSD,
      liquidityUSD,
      dayData: dayDatas,
      hourData: hourDatas,
    }
  }, [anyError, anyLoading, parsedArena, parsedDayDatas, parseHourDatas])

  return { loading, error: anyError, data: formattedData, startPolling: startPolling}
}
