import { useSelector } from 'react-redux'
import { AppState } from '../index'

export function usePriceFromSymbol(symbol: string) {
    return useSelector((state: AppState) => {
        const p = state.prices.data.find(f => f.name === symbol)
        return p ? p.price : 0
    })
}

export function usePrices () {
    return useSelector((state: AppState) => state.prices.data)
}

