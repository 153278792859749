import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import { ResponsiveRow, RowBetween, RowFixed } from 'components/Row'
import LineChart from 'components/LineChart/alt'
import useTheme from 'hooks/useTheme'
import { useProtocolData, useProtocolChartData, useProtocolTransactions } from 'state/protocol/hooks'
import { DarkGreyCard } from 'components/Card'
import { formatDollarAmount } from 'utils/numbers'
import Percent from 'components/Percent'
import { HideMedium, HideSmall, StyledInternalLink } from '../../theme/components'
import TokenTable from 'components/tokens/TokenTable'
import PoolTable from 'components/pools/PoolTable'
import { PageWrapper, ThemedBackgroundGlobal } from 'pages/styled'
import { unixToDate } from 'utils/date'
import BarChart from 'components/BarChart/alt'
import { useAllPoolData } from 'state/pools/hooks'
import { currentTimestamp, notEmpty } from 'utils'
import TransactionsTable from '../../components/TransactionsTable'
import { useAllTokenData } from 'state/tokens/hooks'
import { MonoSpace } from 'components/shared'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import { GET_ARENA_24H, GET_ARENA_DAY, GET_ARENA_DAY_HOUR } from '../../apollo/queries'
import { useArenaData } from 'state/arena/hooks'
import { ChartDayData } from 'types'
import { useBattleData } from 'state/battle/hooks'

const ChartWrapper = styled.div`
  width: 49%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

// const ChartWrapper = styled.div`
//   display: flex;
//   > div {
//     flex: 1;
//     border: 1px double transparent;
//     background-clip: padding-box, border-box;
//     // background-image: linear-gradient(#121a27, #121a27),linear-gradient(180deg, #353848, #6a708b)!important;
//     background-origin: border-box;
//     border-radius: 6px;
//     // border: 1px solid #6A708B;
//     padding: 20px;
//     margin-right: 24px;
//     &:last-child {
//       margin-right: 0;
//     }
//   }
// `
// linear-gradient(180deg,·#353848,·#6a708b)
// linear-gradient(180deg,·#353848,·#6a708b)

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const theme = useTheme()

  const [arenaData] = useArenaData()
  const [battleData] = useBattleData()
  const [protocolData] = useProtocolData()
  const [chartData] = useProtocolChartData()
  const [transactions] = useProtocolTransactions()

  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [leftLabel, setLeftLabel] = useState<string | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()

  const multiBattle = battleData?.data ? battleData.data : []

  function groupBy(objectArray: any[], property0: string, property1: string) {
    return objectArray.reduce(function (acc: any, obj: any) {
      const key0 = obj[property0]
      const key2 = obj[property1]
      const key = key0 + '' + key2
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }
  const newBattles = groupBy(multiBattle, 'underlying', 'collateralSymbol')
  // console.log('newbattl es')
  const battleList = []
  for (const k in newBattles) {
    const b = newBattles[k].reduce((acc: any, cv: any, ci: number) => {
      if (ci === 0) {
        acc['underlying'] = cv['underlying']
        acc['collateralSymbol'] = cv['collateralSymbol']
        acc.liquidityUSD = cv.liquidityUSD
        acc.volumeUSD24H = cv.volumeUSD24H
        acc.volumeUSD7D = cv.volumeUSD7D
      } else {
        // console.log(`cv lusd ${cv.liquidityUSD}`)
        acc.liquidityUSD += cv.liquidityUSD
        acc.volumeUSD24H += cv.volumeUSD24H
        acc.volumeUSD7D += cv.volumeUSD7D
      }
      return acc
    }, {})
    battleList.push(b)
  }
  // console.log('arenaData', arenaData?.hourData)
  const volume24h = arenaData?.hourData.reduce((acc: any, cV: any) => {
    if (cV.date >= currentTimestamp() / 1000 - 24 * 3600) {
      return acc + cV.volumeUSD
    } else {
      return acc
    }
  }, 0)
  // console.log("volume24h", volume24h)
  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (!volumeHover) {
      setVolumeHover(volume24h)
    }
  }, [volumeHover]) 
  useEffect(() => {
     setVolumeHover(volume24h)
  }, [volume24h])
  useEffect(() => {
    // console.log(liquidityHover)
    setLiquidityHover(arenaData?.liquidityUSD)
  }, [arenaData])

  useEffect(() => {
    // console.log(liquidityHover)
    if (!liquidityHover) {
      setLiquidityHover(arenaData?.liquidityUSD)
    }
  }, [liquidityHover])
  // console.log(arenaData?.dayData)
  const liquidity = arenaData?.dayData.reduce((pV: any, cV: any, ci: number, array: ChartDayData[]) => {
    // console.log("view::array", array)
    let tvl = 0
    for (let i=0; i <= ci; i++) {
      tvl += array[i].tvlUSD
    }
    const data = {
      time: unixToDate(cV.date),
      value: tvl,
    }
    pV.push(data)
    return pV
  }, [])
  // console.log(liquidity)

  const volume = arenaData?.dayData.reduce((pV: any, cV: any) => {
    const data = {
      time: unixToDate(cV.date),
      value: cV.volumeUSD,
    }
    pV.push(data)
    return pV
  }, [])
  // console.log("Home::volume", volume)

  return (
    <PageWrapper>
      <ThemedBackgroundGlobal backgroundColor={'#0186ef'} />
      <AutoColumn gap="16px">
        <TYPE.main fontSize="19px">Divergence Overview</TYPE.main>
        <ResponsiveRow>
          <ChartWrapper>
            <LineChart
              data={liquidity}
              height={220}
              minHeight={332}
              color={theme.blue1}
              value={liquidityHover}
              label={leftLabel}
              setValue={setLiquidityHover}
              setLabel={setLeftLabel}
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px">Liquidity</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                    <MonoSpace>{formatDollarAmount(liquidityHover, 2, true)} </MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {leftLabel ?? ''}
                    {/* {leftLabel ? (
                      <MonoSpace>{leftLabel}</MonoSpace>
                    ) : (
                      <MonoSpace>{dayjs.utc().format('MMM D, YYYY')}</MonoSpace>
                    )} */}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
          <ChartWrapper>
            <BarChart
              height={220}
              minHeight={332}
              data={volume}
              color={theme.blue1}
              setValue={setVolumeHover}
              setLabel={setRightLabel}
              value={volumeHover}
              label={rightLabel}
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px">Volume 24H</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                    <MonoSpace> {formatDollarAmount(volumeHover, 2)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {rightLabel ?? ''}
                    {/* {rightLabel ? (
                      <MonoSpace>{rightLabel}</MonoSpace>
                    ) : (
                      <MonoSpace>{dayjs.utc().format('MMM D, YYYY')}</MonoSpace>
                    )} */}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
        </ResponsiveRow>
        {/* <HideSmall>
          <DarkGreyCard>
            <RowBetween>
              <RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Volume 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(protocolData?.volumeUSD)}</TYPE.label>
                  <Percent value={protocolData?.volumeUSDChange} wrap={true} />
                </RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Fees 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(protocolData?.feesUSD)}</TYPE.label>
                  <Percent value={protocolData?.feeChange} wrap={true} />
                </RowFixed>
                <HideMedium>
                  <RowFixed mr="20px">
                    <TYPE.main mr="4px">TVL: </TYPE.main>
                    <TYPE.label mr="4px">{formatDollarAmount(protocolData?.tvlUSD)}</TYPE.label>
                    <TYPE.main></TYPE.main>
                    <Percent value={protocolData?.tvlUSDChange} wrap={true} />
                  </RowFixed>
                </HideMedium>
              </RowFixed>
            </RowBetween>
          </DarkGreyCard>
        </HideSmall> */}
        {/* <RowBetween>
          <TYPE.main>Top Pools</TYPE.main>
          <StyledInternalLink to="/tokens">Explore</StyledInternalLink>
        </RowBetween>
        <TokenTable tokenDatas={formattedTokens} /> */}
        <RowBetween style={{ display: 'block' }}>
          <div style={{ height: '20px' }}></div>
          <TYPE.main fontSize="19px">Top Pairs</TYPE.main>
          {/* <StyledInternalLink to="/pools">Explore</StyledInternalLink> */}
        </RowBetween>
        <PoolTable poolDatas={battleList} />
        {/* <RowBetween>
          <TYPE.main>Transactions</TYPE.main>
        </RowBetween>
        {transactions ? <TransactionsTable transactions={transactions} /> : null} */}
      </AutoColumn>
    </PageWrapper>
  )
}
