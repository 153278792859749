import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { Battle, BattleData, BattleDayHour } from 'state/battle/reducer'
import { ChartDayData } from 'types'
import { currentTimestamp } from 'utils'

export const BATTLE_DATA = () => {
  const queryString = `
    query {
     battles (first: 1000) {
       id
       collateral
       collateralSymbol
       underlying
       volumeUSD
       liquidityUSD
       liquidityCollateral
       peroidType
       settleType
       settleValue
       strikePrice
       strikePriceOver
       strikePriceUnder
       spearPrice
       shieldPrice
       spearSold
       spearNotSold
       shieldSold
       shieldNotSold
       createAt
     }
     battleDayDatas (first: 1000) {
       addr
       date
       volumeUSD
       liquidityUSD
     }
     battleHourDatas (first: 1000) {
       addr
       date
       volumeUSD
       liquidityUSD
     }
   }
	`
  return gql(queryString)
}

interface BattleResponse {
  battles: {
    id: string
    collateral: string
    collateralSymbol: string
    underlying: string
    volumeUSD: string
    liquidityUSD: string
    liquidityCollateral: string
    peroidType: number
    settleType: number
    settleValue: string
    strikePrice: string
    strikePriceOver: string
    strikePriceUnder: string
    spearPrice: string
    shieldPrice: string
    spearSold: string
    spearNotSold: string
    shieldSold: string
    shieldNotSold: string
    createAt: number
  }[]
  battleDayDatas: {
    addr: string
    date: number
    volumeUSD: string
    liquidityUSD: string
  }[]
  battleHourDatas: {
    addr: string
    date: number
    volumeUSD: string
    liquidityUSD: string
  }[]
}

export function useFetchBattleData(): {
  loading: boolean
  error: boolean
  data: BattleData | undefined
  startPolling: (pollInterval: number) => void
} {
  // const eTimestamp = Math.round(new Date().getTime() / 1000)  //  重新获取当前时间
  // const sTimestamp = eTimestamp - 86400

  // console.log("newTimeStamp", sTimestamp)
  // console.log("newTimeStamp", eTimestamp)
  const { loading, error, data, startPolling } = useQuery<BattleResponse>(BATTLE_DATA(), {pollInterval: 2000})
  
  if (error) {
    console.log(`battle fetchData Error ${error}`)
  }
  // console.log('rawdata', data)
  const anyError = Boolean(error)
  const anyLoading = Boolean(loading)

  const formattedData: BattleData | undefined = useMemo(() => {
    
    let hourDatas: BattleDayHour[] = []
    let _dayDatas: BattleDayHour[] = []
    if (data?.battleHourDatas) {
      hourDatas = data.battleHourDatas.reduce((pv: any, cv: any) => {
        const d = {
          addr: cv.addr,
          date: cv.date,
          volumeUSD: parseFloat(cv.volumeUSD),
          liquidityUSD: parseFloat(cv.liquidityUSD),
        }
        pv.push(d)
        return pv
      }, [])
    }

    if (data?.battleDayDatas) {
      _dayDatas = data.battleDayDatas.reduce((pv: any, cv: any) => {
        const d = {
          addr: cv.addr,
          date: cv.date,
          volumeUSD: parseFloat(cv.volumeUSD),
          liquidityUSD: parseFloat(cv.liquidityUSD),
        }
        pv.push(d)
        return pv
      }, [])
    }

    const hourData24H = hourDatas.filter(data => data.date >= currentTimestamp()/1000 - 24*3600)
    const dayData7D = _dayDatas.filter(data => data.date >= currentTimestamp()/1000 - 24*3600*7)
    // const dayData7D = 
    // console.log("data::hourData24H", hourData24H)
    const hourDay24HByAddr = hourData24H.reduce((pv: any, cv: any) => {
      const key = 'addr'
      if (!pv[cv[key]]) {
        pv[cv[key]] = cv.volumeUSD
      } else {
        pv[cv[key]] += cv.volumeUSD
      }
      return pv
    }, {})

    const hourDay7DByAddr = dayData7D.reduce((pv: any, cv: any) => {
      const key = 'addr'
      if (!pv[cv[key]]) {
        pv[cv[key]] = cv.volumeUSD
      } else {
        pv[cv[key]] += cv.volumeUSD
      }
      return pv
    }, {})

    // console.log("data::hourDay24HByAddr", hourDay24HByAddr)

    // console.log("data::hourDay7DByAddr", hourDay7DByAddr)
    let battlesData: Battle[] = []
    if (data?.battles) {
      battlesData = data.battles.reduce((pv: any, cv: any) => {
        let volumeUSD24H = 0
        let volumeUSD7D = 0
        if (hourDay24HByAddr[cv.id]) {
          volumeUSD24H = hourDay24HByAddr[cv.id]
        }
        if (hourDay7DByAddr[cv.id]) {
          volumeUSD7D = hourDay7DByAddr[cv.id]
        }
        // add only one price 
        let price = 0
        if (cv.settleType === '0') {
          price = parseFloat(cv.strikePriceUnder)
        } else if (cv.settleType === '1') {
          price = parseFloat(cv.strikePriceOver)
        } else if (cv.settleType === '2') {
          price = parseFloat(cv.strikePriceUnder)
        } else if (cv.settleType === '3') {
          price = parseFloat(cv.strikePrice)
        }
        const d = {
          address: cv.id,
          collateral: cv.collateral,
          collateralSymbol: cv.collateralSymbol,
          underlying: cv.underlying,
          volumeUSD: parseFloat(cv.volumeUSD),
          liquidityUSD: parseFloat(cv.liquidityUSD),
          liquidityCollateral: parseFloat(cv.liquidityCollateral),
          peroidType: parseFloat(cv.peroidType),
          settleType: parseFloat(cv.settleType),
          settleValue: parseFloat(cv.settleValue),
          strikePrice: parseFloat(cv.strikePrice),
          strikePriceOver: parseFloat(cv.strikePriceOver),
          strikePriceUnder: parseFloat(cv.strikePriceUnder),
          spearPrice: parseFloat(cv.spearPrice),
          shieldPrice: parseFloat(cv.shieldPrice),
          spearSold: parseFloat(cv.spearSold),
          spearNotSold: parseFloat(cv.spearNotSold),
          shieldSold: parseFloat(cv.shieldSold),
          shieldNotSold: parseFloat(cv.shieldNotSold),
          createAt: cv.createAt,
          volumeUSD24H: volumeUSD24H,
          volumeUSD7D: volumeUSD7D,
          price
        }
        pv.push(d)
        return pv
      }, [])
    }

    let dayDatas: BattleDayHour[] = []
    if (data?.battleDayDatas) {
      dayDatas = data.battleDayDatas.reduce((pv: any, cv: any) => {
        const d = {
          addr: cv.addr,
          date: cv.date,
          volumeUSD: parseFloat(cv.volumeUSD),
          liquidityUSD: parseFloat(cv.liquidityUSD),
        }
        pv.push(d)
        return pv
      }, [])
    }

    return {
      data: battlesData,
      dayData: dayDatas,
      hourData: hourDatas,
    }
  }, [anyError, anyLoading, data])

  // console.log('formatteddata', formattedData)

  return { loading, error: anyError, data: formattedData, startPolling: startPolling }
}
